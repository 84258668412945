/* ==========================================================================
   
   LOGOS

========================================================================== */

.logo-block {

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-evenly;
	margin-left: auto;
	margin-right: auto;

	max-width: 970px;

	@media (min-width: 970px) {

		justify-content: center;

	}

	@media (max-width: 970px) {

		margin-top: 60px;

	}

	.logo {
		
		position: relative;
		margin-left: 70px;
		margin-right: 70px;
		margin-bottom: 70px;
		width: auto;
		text-align: center;

		@include media-breakpoint-up(md) {

			margin-bottom: 115px;

		}

		@media (min-width: 1100px) {

			width: 230px;

		}

		&:before {

			@media (min-width: 970px) {

				content: '';
				background: $brand;
				width: 1px;
				height: 180px;
				position: absolute;
				left: -50%;
				margin-left: 45px;
				top: -70px;
				opacity: 0.3;

			}

		}

		@media (min-width: 970px) {

			&:first-child {

				margin-left: 0;

				&:before {

					display: none;

				}

			}

			&:nth-child(3n) {

				margin-right: 0;

			}

			&:nth-child(4n) {

				margin-left: 0;

				&:not(:last-child) {

					&:before {

						display: none;

					}

				}


			}

			&.end {

				margin-right: 0;

			}

		}

	}

	img {

		max-width: 180px;

	}

	.leading {

		margin-bottom: 40px;
		text-align: center;

		@include media-breakpoint-up(lg) {

			margin-bottom: 115px;

		}

	}

}