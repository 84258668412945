.tailored-choices-slider {

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
  }

  .slick-track {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
    }
  }

  .news-image {
    background-size: cover;
    height: 215px;
    width: 435px;

    @include media-breakpoint-down(md) {
      height: 300px;
    }
  }

  .news-content {
    @include media-breakpoint-down(md) {
      background: rgba(0, 0, 0, 0.6);
      padding-left: 4vw;
      padding-right: 4vw;
    }


    p {
      @include media-breakpoint-down(md) {
        color: $white;
        display: block;
      }
    }
  }

  .slider-next {
    top: 37%;

    @media (max-width: 699px) {
      right: -1vw;
    }

    @media (min-width: 700px) and (max-width: 799px) {
      right: 7vw;
    }

    @media (min-width: 800px) and (max-width: 899px) {
      right: 5vw;
    }

    @media (min-width: 899px) and (max-width: 991px) {
      right: 3vw;
    }

    @media (min-width: 992px) and (max-width: 1200px) {
      right: -94px;
    }

    @media (min-width: 1200px) {
      right: 105px;
    }
  }

  .slider-prev {
    left: auto;
    top: 51%;

    @media (max-width: 699px) {
      right: -1vw;
    }

    @media (min-width: 700px) and (max-width: 799px) {
      right: 7vw;
    }

    @media (min-width: 800px) and (max-width: 899px) {
      right: 5vw;
    }

    @media (min-width: 899px) and (max-width: 991px) {
      right: 3vw;
    }

    @media (min-width: 992px) and (max-width: 1200px) {
      right: -94px;
    }

    @media (min-width: 1200px) {
      right: 105px;
    }
  }
}
