/* ==========================================================================
   
   ANIMATIONS

========================================================================== */

@keyframes heroArrow {

	0% {

		height: 0;
		opacity: 0;

	}

	100% {

		height: 50px;
		opacity: 1;

	}

}

@keyframes hero {

	100% {

		transform: scale(1);

	}
	
}

@keyframes headerFade {

	100% {

		opacity: 1;

	}

}

@keyframes navHomeFade {

  100% {

    opacity: 1;

  }

}

@keyframes heroHomeTitle {

  100% {

    opacity: 1;
    transform: translateY(0);

  }

}

/* ==========================================================================
   
   AOS CUSTOM

 ========================================================================== */
[data-aos="e3-fade-up"] {

  transform: translateY(20px);
  opacity: 0;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;

  &.aos-animate {

    transform: translateY(0);
    opacity: 1;

  }

}

[data-aos="e3-fade-left"] {

  transform: translateX(-40px);
  opacity: 0;
  transition: all 0.4s ease-in-out;

  &.aos-animate {

    transform: translateX(0);
    opacity: 1;

  }

}

[data-aos="e3-fade-right"] {

  transform: translateX(40px);
  opacity: 0;
  transition: all 0.4s ease-in-out;

  &.aos-animate {

    transform: translateX(0);
    opacity: 1;

  }

}

[data-aos="e3-fade-in"] {

  opacity: 0;
  transition: all 0.4s ease-in-out;

  &.aos-animate {

    opacity: 1;

  }

}

[data-aos="e3-scale-up"] {

  transform: scale(0.96, 0.96);
  opacity: 0;
  transition: all 0.4s ease-in-out;

  &.aos-animate {

    opacity: 1;
    transform: scale(1);

  }

}

[data-aos="e3-bg-text"] {

  transform: scale(0.96, 0.96);
  opacity: 0;
  transition: all 1.2s ease-in-out;

  &.aos-animate {

    opacity: 0.1;
    transform: scale(1);

  }

}