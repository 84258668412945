/* ==========================================================================
   
   MIXINS
   
========================================================================== */

/* LOOP THROUGH HEADRS
========================================================================== */
@function headings($from:1, $to:6) {
    @if $from == $to {
        @return 'h#{$from}';
    } @else {
        @return 'h#{$from},' + headings($from+1, $to);
    }
}

/* ICONS
========================================================================== */

@mixin icons() {

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@mixin background-cover($pos:center) {
  background-size:cover;
  background-position:$pos;
  background-repeat:no-repeat;
}

@mixin background-contain() {
  background-size:contain;
  background-position:center;
  background-repeat:no-repeat;
}

@mixin background-size($size:50%) {
  background-size:$size;
  background-position:center;
  background-repeat:no-repeat;
}

@mixin webkit-line-clamp($clamp: 4, $height: 120px) {
  overflow: hidden;
  height: $height;
  -webkit-line-clamp: $clamp;
  text-overflow:ellipsis;
  display: -webkit-box;
}

@mixin arrow($color : #fff) {

    &:before {

      content: '';
      position: absolute;
      left: 0;
      width: 6px;
      height: 6px;
      border-right: 1px solid $color;
      border-top: 1px solid $color;
      transform: rotate(-135deg) translate(2px,2px);
      
    }

    &:after {
      
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: inline-block;
      background: $color;
      width: 25px;
      height: 1px;

    }

}
