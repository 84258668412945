/* ==========================================================================
   
   BLOCK BANNER

========================================================================== */

.block-banner {

	position: relative;
	background: $brand-red;
	padding: 40px 30px;
	margin-top: 20px;
	margin-bottom: 45px;

	@include media-breakpoint-up(lg) {

		padding: 120px 30px;
		margin-bottom: 100px;

	}

	.banner {

		text-align: center;

		.title {

			color: $white;
			font-size: 28px;
			line-height: 56px;

			margin-bottom: 10px;

			> span {

				font-size: 52px;
				font-family: $font-sacramento;

			}

		}

		.inner-link {
			color:white;
			font-size: 16px;
    		margin-top: 35px;
    		display: inline-block;	
    		padding: 0 30px;		
		}

		p {

			color: $white;
			text-align: center;
			max-width: 380px;
			margin-left: auto;
			margin-right: auto;

		}

	}

	.banner-inner {

		position: relative;
		z-index: 10;

	}

	.banner-slider {

		@include media-breakpoint-down(md) {

			margin-bottom: 90px;

		}

	}

	.banner-line {

		background: url('../images/line.svg') repeat;
		background-size: auto 70%;
		background-position: 10% 0%;

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 150%;
		opacity:0.3;

		transition: background-position 1.5s ease-in-out;

	}

	.slick-prev,
	.slick-next {

		z-index: 500;

	}

	.banner-side {

		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;
		z-index: 10;

		&.side-left {

		}

		&.side-right {
			
			left: auto;
			right: 0;

		}

	}

	.button-group {

		margin-top: 30px;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;

		.col {

			margin-bottom: 30px;

			&:last-child {

				margin-bottom: 0;
				
			}

			@include media-breakpoint-up(lg) {

				width: 50%;
				margin-bottom: 0;

			}

		}

	}

	.banner-img {

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 5;
		width: 100%;
		height: 100%;

		background-size: cover;
		background-repeat: no-repeat;

	}

}

.slider-count {

	position: absolute;
	bottom: 30px;
	left: 0;
	right: 0;
	width: 50px;
	margin: 0 auto;


	@include media-breakpoint-up(lg) {

		bottom: 50px;
		opacity: 0;

	}

	.arrows {

		position: relative;
		height: 1px;

		margin: 15px 0 10px 0;

		.arrow {

		    position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);

			display: inline-block;
			
			background: $white;
			width: 25px;
			height: 1px;

			cursor: pointer;
			transition: opacity 0.3s ease;

			&:before {
				
				content: '';
				position: absolute;
				left: 0;
				width: 6px;
				height: 6px;
				border-right: 1px solid #fff;
				border-top: 1px solid #fff;
				transform: rotate(-135deg) translate(2px, 2px);

			}

			&-next {

				left: auto;
				right: 0;

				&:before {

					left: auto;
					right: 0;
					transform: rotate(45deg) translate(-2px,-2px)

				}

			}

			&.inactive {

				opacity: 0.5;

			}

		}

	}

	.number {

		font-size: 10px;
		color: $white;

	}

}