/* ==========================================================================
   
   PROFILE CLOUD

========================================================================== */

.profile-cloud {

	position: relative;
	text-align: center;

	margin-bottom: 95px;

	@include media-breakpoint-up(lg) {

		max-width: 86%;

	}

	&:before,
	&:after {

		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-50%);
		margin-left: auto;
		margin-right: auto;

		background: $brand-light;
		opacity: 0.3;

		@include media-breakpoint-down(xs) {

			display: none;

		}

	}

	&:before {

		height: 185px;
		width: 1px;

	}

	&:after {

		width: 185px;
		height: 1px;

	}

	.profile {

		margin-bottom: 45px;

		@include media-breakpoint-up(sm) {

			margin-bottom: 95px;

		}

		&:nth-last-child(-n+2) {

			@include media-breakpoint-up(sm) {

				margin-bottom: 0;
				
			}

		}

		&:last-child {

			@include media-breakpoint-down(xs) {

				margin-bottom: 0;

			}
			
		}

		.profile-img {

			width: 320px;
			height: 320px;

			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: inline-block;
			margin-bottom: 15px;

			@include media-breakpoint-up(sm) {

				width: 120px;
				height: 120px;

			}

			@include media-breakpoint-up(md) {

				margin-bottom: 35px;

				width: 320px;
				height: 320px;

			}

		}

		.title {

			font-size: 28px;
			color: $brand;
			margin-bottom: 5px;

		}

		p {

			color: $brand;

		}

	}

}