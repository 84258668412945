/* ==========================================================================
   
   TESTIMONIALS

========================================================================== */

.testimonial {

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 10px;

	@include media-breakpoint-up(lg) {

		padding: 20px 0 0 0;
		flex-direction: row;

	}

	.quote {

		max-width: 450px;
		width: 100%;
		text-align: center;

		opacity: 0;
		transform: translateY(-20px);
		transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
		order: 2;

		@include media-breakpoint-up(lg) {

			order: 1;
			min-width: 450px;
			margin-right: 120px;
			transform: translateX(-20px);

		}

		p {

			font-family: $font-sacramento;
			font-size: 36px;
			line-height: 50px;
			color: $brand;
			margin-bottom: 20px;

			@include media-breakpoint-up(lg) {

				font-size: 52px;
				line-height: 70px;
				margin-bottom: 40px;

			}

		}

		.author {

			font-size: 10px;
			color: $brand-alt;

		}

	}

	.image {

		position: relative;
		height: 80vw;
		width: 80vw;
		max-width: 500px;
		max-height: 500px;
		order: 1;
		margin-bottom: 60px;
		transform: rotate(4deg);
		transition: transform 0.6s ease-in-out;
		@include background-cover();
		@include media-breakpoint-up(md) {
			height: 40vw;
			width: 40vw;
		}

		@include media-breakpoint-up(lg) {

			order: 2;
			margin-bottom: 0;


		}

		&__photographer {
			color: $brand-alt;
			font-family: $font-base;
			font-size: 10px;
			font-weight: 700;
			position: absolute;
			bottom: 30px;
			left: 50%;
			transform: translateX(-50%);

			a {
				color: $brand-alt;
				font-family: $font-base;
				text-decoration: underline;
			}
		}

		.slide-count {

			position: absolute;
			bottom: 30px;
			right: 30px;
			z-index: 1;

			width: 36px;
			height: 42px;

			@include media-breakpoint-down(lg) {

				display: none;

			}

			&:before {

				content: '';
				position: absolute;
				left: -4px;
				top: calc(50% + 2px);
				transform: translateY(-50%) rotate(-45deg);

				width: calc(100% + 10px);
				background: $white;
				height: 1px;

			}

			.number {

				color: $white;
				font-size: 12px;
				font-family: $font-helvetica;

				&.top {

					position: absolute;
					left: 2px;
					top: 0;

				}

				&.bottom {

					position: absolute;
					bottom: 0;
					right: 0px;

				}

			}

		}

	}

}

.testimonials {

	position: relative;
	margin-bottom: 60px;

	.type1A, .bg-animation {
		position: absolute;
		top: -200px;
		left: 0;
		right: 0;
		z-index: -1;
		pointer-events: none;

		@include media-breakpoint-down(lg) {

			display: none;

		}

	}

}

.slider-next,
.slider-prev {
		
	position: absolute;
	top: calc(50% - 120px);
	right: 10px;

	width: 50px;
	height: 50px;
	background: $brand;
	display: block;

	@include media-breakpoint-up(lg) {

		top: 50%;
		right: 0;
		transform: translateY(-50%);
		width: 60px;
		height: 60px;

	}

	&:hover .arrow {

		@include media-breakpoint-up(lg) {

			transform: translateX(10px);

		}

	}

	.arrow {

		position: relative;
		width: 100%;
		height: 100%;
		display: block;
		transition: transform 0.4s ease-in-out;

		@include arrow;

		&:before {
			
			top: 50%;
		    left: 24px;
		    transform: rotate(45deg) translate(-28%,-44%);
			
		}

		&:after {

			left: -20px;
			width: 50px;

		}

	}

}

.slider-prev {
	
	right: auto;
	left: 0;

	transform: rotate(180deg);

}

.testimonials-slider {

	.slick-slide {

		&.show-behind {

			opacity: 1!important;

		}

		&.slick-active {

			.testimonial {

				.quote {

					opacity: 1;
					transform: translateY(0);

					@include media-breakpoint-up(lg) {

						transform: translateX(0);

					}

				}

				.image {

					transform: rotate(0);

				}

			}

			+ .slick-slide {

				opacity: 1!important;

			}

		}

	}

}