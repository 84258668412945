/* ==========================================================================
   
   LATEST NEWS

========================================================================== */

.latest-news {

	position: relative;
	background: rgba($brand-light, 0.2);
	padding: 50px 0;

	.news-item {

		position: relative;
		display: flex;

		align-items: center;

	}

	.news-image {

		position: relative;
		max-width: 435px;

		.image-inner  {
			height: 325px;
			width: 470px;
			max-width: 100%;
			margin: auto;
			display: block;
			@include background-cover();
		}

		.meta {

			position: absolute;
			top: 50%;
			right: -20px;
			transform: rotate(-90deg) translateX(50%);
			transform-origin: right top;

			font-size: 12px;
			color: $brand-light;

			@include media-breakpoint-down(lg) {

				display: none;

			}

		}

	}

	.news-content {

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		flex-direction: column;
		max-width: 435px;

		padding-left: 5vw;

		.meta {

			font-size: 12px;
			color: $white;
			margin-bottom: 10px;

			@include media-breakpoint-up(lg) {

				display: none;

			}

		}

		@include media-breakpoint-up(lg) {
			
			position: static;
			display: block;
			max-width: 350px;
			padding-left: 0;
			margin-left: 50px;
			background: none;

		}

		@include media-breakpoint-up(xl) {

			margin-left: 100px;

		}

		.title {

			font-size: 21px;
			color: $white;

			@include media-breakpoint-up(lg) {

				color: $brand;
				font-size: 28px;
				margin-bottom: 15px;

			}

		}

		p {

			color: $brand-light;
			margin-bottom: 15px;

			@include media-breakpoint-down(md) {

				display: none;

			}

		}

		a {

			margin-right: 30px;

			@include media-breakpoint-down(md) {

				display: none;

			}

		}

	}

	.overlay-text-mobile {

		@extend .type1A;
		opacity: 1!important;
		font-size: 46px;
		color: $brand;
		text-align: center;
		display: block;
		margin-bottom: 20px;

	}

	.overlay-text {

		@extend .type1A;
	
		position: absolute;
		top: 50%;
		left: -160px;
		z-index: 1;
		opacity: 1!important;
		transform: translateY(-50%);

		font-size: 72px;
		color: $white;

		@include media-breakpoint-down(lg) {

			display: none;

		}
		
	}

	&.spacing {

		margin-bottom: 50px;

		@include media-breakpoint-up(lg) {

			margin-bottom: 100px;

		}

	}

}

.latest-news-slider {

	@media (min-width: 620px) {

		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
		
	}

	@media (min-width: 1200px) {

		max-width: 90%;
		left: 6%;
		
	}

	.slick-active {

		opacity: 1;

		& + .slick-slide {

			margin-left: -20px;
			opacity: 0.3;
			transition: opacity 0.3s ease-in-out;

			@media (min-width: 620px) {

				margin-left: -150px;

			}

			@media (min-width: 700px) {

				margin-left: -220px;

			}

			@include media-breakpoint-up(lg) {

				margin-left: -135px;

			}

		}

	}
	
	.slick-list {

		@include media-breakpoint-down(lg) {

			width: 100vw;
			margin-left: -5vw;

		}

	}

	.slick-slide {
		
		@include media-breakpoint-down(lg) {

			margin-right: 50px;

		}

	}

	.slider-next {


		@include media-breakpoint-down(lg) {
			
			top: 50%;
			transform: translateY(-50%);
			right: -5vw;

		}
		@media (min-width: 620px) and (max-width: 1200px) {

			right: -15%;

		}		

		right: 0;

	}

}