/* ==========================================================================
   
   COLOURS

========================================================================== */


$white: #fff;
$black: #000;
$grey: #eeeeee;
$grey-light: #fcfcfd;
$grey-dark: #222222;

$brand: #314871;
$brand-dark: #192536;
$brand-darker: #131D2B;
$brand-dark-alt: #003764;

$brand-light: #878A96;
$brand-alt: #B49A64;
$brand-red: #550011;

$heading-color: $brand;
$text-color: #878A96; 

/* ==========================================================================
   
   TYPE

========================================================================== */

$font-base: Georgia, Times, "Times New Roman", serif;
$font-secondary: 'Sacramento', cursive;
$font-helvetica: 'Helvetica', 'arial', sans-serif;
$font-georgia: $font-base;
$font-sacramento: $font-secondary;

$font-regular: 400;
$font-semibold: 500;
$font-bold: 700;

$font-size: 14px;

/* ==========================================================================
   
   GRID SETTINGS

========================================================================== */

$main-sm-columns:       12;
$sidebar-sm-columns:    4;
