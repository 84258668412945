/* ==========================================================================
   
   HEADER

========================================================================== */

header.primary {

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	@include z-index(header);
	transform: translateY(0);
	transition: background 0.4s ease-in-out, transform 0.3s ease-in-out;

	&.hidden {

		transform: translateY(-100%);

	}

	&.transparent {

		background: transparent;
		animation: headerFade 1s forwards;
		opacity: 0;

		&--dark {

			background: transparent;

			nav.primary .nav-primary li {
				
				&:before {

					color: rgba($brand-alt, 0.5);
				}
				
				a {

					color: $brand;
					transition: color 0.6s ease;

				}

			}

			.appointment {

				color: $brand;

				a {

					color: $brand;

					&:before {

						background: $brand;

					}

				}

			}

		}

	}

	&.white {

		background: $white;

		nav.primary .nav-primary li {
			
			&:before {

				color: rgba($brand-alt, 0.5);
			}
			
			a {

				color: $brand;

			}

		}

		.appointment {

			color: $brand;

			a {

				color: $brand;

				&:before {

					background: $brand;

				}

			}

		}

	}

	&.brand {

		background: $brand;

	}

	&.active {

		background: $brand-dark-alt;

	}

	.header {

		display: flex;
		align-items: center;

		@include media-breakpoint-up(lg) {

			align-items: baseline;

		}

	}

	.logo {

		position: relative;
		display: inline-block;
		padding: 10px 15px;
		z-index: 5;
		overflow: hidden;

		&:before {

			content: '';
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;

			background: url(../../assets/images/header-bg.jpg) no-repeat;
			background-position: top left;
			filter: blur(10px);

		}

		&:after {

			content: '';
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			width: 100%;
			height: 100%;
			background: $white;
			opacity: 0.7;

		}

		img {

			max-width: 36px;
			position: relative;
			z-index: 3;

			@include media-breakpoint-up(lg) {

				max-width: 45px;

			}

		}

	}

	.menu-mobile {

		position: relative;
		margin-left: auto;
		padding-bottom: 8px;

		@include media-breakpoint-up(lg) {

			display: none;

		}

		.hamburger {

			position: relative;
			display: inline-block;
			height: 14px;
			width: 40px;
			border-bottom: 1px solid $white;
			transition: border-color 0.6s linear;

			&.active {

				&:before {

					transform: translateY(4px);

				}

				&:after {

					transform: translateY(-4px);

				}

			}

			&.revealed {

				border-color: $brand;

				&.active {

					border-color: $white;

					&:before,
					&:after {

						background: $white;

					}

				}

				&:before,
				&:after {

					background: $brand;

				}

			}

			&:before,
			&:after {

				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 1px;
				background: $white;
				transition: transform 0.3s ease-in-out, background 0.6s linear;

			}

			&:after {

				top: auto;
				bottom: -14px;

			}

		}

	}

}

nav.primary {

	position: absolute;
	top: 84px;
	left: 0;
	right: 0;
	width: 100%;

	opacity: 0;
	visibility: hidden;
	transition: opacity 0.4s ease-in-out;

	&:before {

		content: '';
		background: $brand-dark-alt;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		
		width: 100%;
		height: 0;
		opacity: 0;
	
		transition: all 0.5s ease-in-out;

		@include media-breakpoint-up(lg) {

			display: none;

		}

	}

	@include media-breakpoint-up(lg) {

		position: static;
		opacity: 1;
		visibility: visible;

		width: auto;
		margin-left: 30px;

	}

	&.active {
		
		opacity: 1;
		visibility: visible;

		&:before {

			opacity: 1;
			height: 100vh;
			
		}

		.nav-primary li {
			
			opacity: 1;
			transform: translateY(0);

			@for $item from 1 through 20 {
                &:nth-child(#{$item}){
                    transition-delay: ($item)*0.2s;
                }
            }

		}

		.appointment {

			opacity: 1;
			transform: translateY(0);
			visibility: visible;

		}

	}

	.nav-primary {

		text-align: center;
		padding-top: 60px;

		@include media-breakpoint-up(lg) {
			
			padding-top: 0;
			text-align: left;

		}

		li {

			position: relative;
			opacity: 0;
			transform: translateY(10px);
			transition: all 0.4s ease-in-out;

			font-size: 24px;
			margin-bottom: 20px;

			@include media-breakpoint-up(lg) {
				
				opacity: 1;
				transform: translateY(0);

				display: inline-block;
				margin-right: 20px;
				font-size: 16px;
				margin-bottom: 0;
				
			}

			@media (min-width: 1250px) {

				margin-right: 30px;

			}

			&:last-child {

				margin-bottom: 20px;
				margin-right: 0;

			}

			&:hover:before,
			&.active:before {
				
				opacity: 1;
				transform: translateY(-50%) scale(1);

			}

			&:before {

				@include icons;
				content: '\e903';
				
				font-size: 45px;
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				z-index: -1;
				transform: translateY(-50%) scale(0.7);

				color: rgba($white, 0.5);
				text-align: center;
				opacity: 0;
				transition: opacity 0.3s ease, transform 0.5s ease;
				
			}

			a {

				color: $white;
				transition: none;

			}

		}

	}

	.appointment {

		position: relative;
		margin-top: 50px;

		text-align: center;
		font-size: 14px;
		color: $white;

		opacity: 0;
		visibility: hidden;
		transform: translateY(20px);
		transition: all 0.4s ease;

		@include media-breakpoint-up(sm) {

			font-size: 18px;

		}

		@include media-breakpoint-up(lg) {

			display: none;

		}

		@include media-breakpoint-up(xl) {

			opacity: 1;
			visibility: visible;
			margin-top: 0;

			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			
			right: 20px;
			font-size: 16px;
			transition: none;
			display: inline-block;

		}

		a {

			color: $white;
			position: relative;
			transition: none;

			&:before {

				content: '';

				height: 1px;
				width: 30px;
				background: $white;
				display: inline-block;
				margin: 0 10px;

				position: relative;
				top: -4px;

				@include media-breakpoint-up(xl) {

					margin: 0 20px;

				}

			}

		}

	}

}