body.page.home {
  header.primary {
    @include media-breakpoint-up(lg) {
      .nav-primary li {
        opacity: 0;

        @for $item from 1 through 10 {
          &:nth-child(#{$item}) {
            animation: navHomeFade 0.6s ease forwards;
            animation-delay: $item * 0.2s;
          }
        }
      }

      .appointment {
        opacity: 0;
        animation: navHomeFade 0.6s ease forwards;
        animation-delay: 1.4s;
      }
    }
  }

  .hero {
    .sub-title {
      animation: heroHomeTitle 1.2s forwards;
      opacity: 0;
      animation-delay: 0.6s;
      transform: translateY(-20px);
    }

    .title {
      animation: heroHomeTitle 1.2s forwards;
      opacity: 0;
      transform: translateY(-20px);
    }
  }
}

.page-reveal {
  padding-top: 140px;
  min-height: 101vh;
  opacity: 0;
  transition: opacity 1s linear;

  &.page-revealed {
    opacity: 1;
  }
}

.title-underline {
  display: inline-block;
  border-bottom: 1px dashed $brand;
  padding-bottom: 10px;
}

.title-header {
  margin-bottom: 50px;
  text-align: center;

  .title {
    color: $brand;
    font-size: 30px;
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 40px;

  .pagination {
    align-items: center;

    .page-item {
      font-size: 16px;
      padding-left: 8px;
      padding-right: 8px;

      &:focus {
        outline: none;
      }

      .page-link {
        border: none;
        padding: 0;
        line-height: 100%;

        &:focus {
          box-shadow: none;
        }
      }
    }

    a {
      color: $brand-dark;

      &:hover {
        background: none;
        color: $brand-alt;
      }
    }

    .prev,
    .next {
      font-size: 24px;
      padding: 0;
    }

    .prev {
      padding-right: 10px;
    }

    .next {
      padding-left: 10px;
    }

    .current a {
      color: $brand-alt;
    }
  }
}

header.block-title {
  text-align: center;
  margin-bottom: 40px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 100px;
  }


  .title {
    position: relative;
    color: $brand-dark;
    display: inline-block;
    margin-bottom: 30px;

    &:after {
      content: '';
      position: absolute;
      bottom: -15px;
      left: 0;
      width: 100%;
      height: 1px;
      border-bottom: 1px dashed $brand-dark;
    }
  }

  p {
    color: $brand-light;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.page:not(.home) {
  *[data-aos] {
    opacity: 1 !important;
    visibility: visible !important;
    transform: translateY(0) !important;
    transform: translateX(0) !important;
  }
}

.morning-suits,
.dinner-suits,
.lounge-suits {
  @media (max-width: 767px) {
    .wrap > .row {
      padding-top: 84px;
    }
  }
}
