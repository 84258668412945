/* BODY
========================================================================== */

body {

	background: $white;
	color: $text-color;

	font-family: $font-base;

	-webkit-font-smoothing:antialiased;
	-moz-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
	-webkit-text-size-adjust:100%;

}

.wrap {

	position: relative;
	overflow: hidden;

	& > .row {

		padding-top: 100px;
		
	}

}

.container-fluid {

	//max-width: 1600px;
	//padding-left: 5vw;
	//padding-right: 5vw;

	&.reset-styles {
    	max-width: none;
    	padding-left: 15px;
    	padding-right: 15px;
	}
	
}

img {

	max-width: 100%;
	height: auto;
	
}

.content {
	margin-left: auto;
	margin-right: auto;
}

.vertical-outer {
   display: table;
   height: 100%;
   width: 100%;
}

.vertical-inner {
   display: table-cell;
   vertical-align: middle;
}

.sidebar-primary .main {
    max-width: none!important;
    width: 100%;
    flex: none!important;
}

.modal.custom {
	.modal-dialog {
    	max-width: 100%;

		@include media-breakpoint-up(sm) {
    		max-width: 90%;
		}

		@include media-breakpoint-up(md) {
			max-width: 80%;
		}

		.modal-content {
			border-radius: 0;

			video {
				height:250px;

				@include media-breakpoint-up(sm) {
					height:300px;
				}

				@include media-breakpoint-up(md) {
					height:350px;
				}

				@include media-breakpoint-up(lg) {
					height:450px;
				}

				@include media-breakpoint-up(xl) {
					height: 600px;
				}

				background-color:black;
			}
		}
	}
}

.to-front {
    position: relative;
    z-index: 1;	
}

@keyframes draw {
  100% {
    stroke-dashoffset: 0;
  }
}

.slick-dots li button:before {
	font-size:16px;
}