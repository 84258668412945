/* ==========================================================================
   
   LOOKBOOK

========================================================================== */

.lookbooks {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 80px;

  .lookbook-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

    width: 100%;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      width: auto;
    }

    .slick-list {
      position: relative;

      // workaround to not show next slide under/over content
      &:before {
        content: '';
        position: absolute;
        width: calc(500px + 100vw);
        right: calc(-500px - 100vw);
        top: 0;
        bottom: 0;
        height: 100%;
        background: $white;
        z-index: 10;
      }
    }

    &:not(.alt) {
      .slider-next {
        left: 0;
        right: auto;
        z-index: 1;

        transform: translateY(-50%) rotate(180deg);

        @media (min-width: 1100px) {
          left: -60px;
        }
      }
    }

    &.alt {
      .slick-list:before {
        left: calc(-500px - 100vw);
        right: auto;
      }

      .lookbook-images {
        @include media-breakpoint-up(lg) {
          order: 2;
        }

        .open-preview {
          left: 15px;
          right: auto;
        }
      }

      .lookbook-content {
        @include media-breakpoint-up(lg) {
          margin-left: 0;
          margin-right: 60px;
          order: 1;
        }
      }

      .slider-next {
        right: 0;
        z-index: 1;

        @media (min-width: 1100px) {
          right: -60px;
        }
      }

      .slick-slide {
        opacity: 0;

        &.slick-active {
          opacity: 1;

          & + .slick-slide {
            opacity: 0.2;
          }
        }
      }

      .slick-slide.slick-slide-active {
        opacity: 1;
      }
    }
  }

  .lookbook-images {
    width: 100%;
    margin-bottom: 20px;
    //direction: rtl;

    @include media-breakpoint-up(sm) {
      width: 70%;
    }

    @include media-breakpoint-up(lg) {
      max-width: 500px;
      width: auto;
      margin-bottom: 0;
    }

    .item {
      position: relative;
    }

    .open-preview {
      position: absolute;
      bottom: 15px;
      right: 15px;

      width: 30px;
      height: 30px;

      border: 1px solid $white;
      border-radius: 100%;

      opacity: 0;
      transform: scale(1);
      transition: opacity 0.5s ease-in-out, transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.3);
      }

      .cross {
        position: absolute;
        width: 100%;
        height: 100%;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;

          width: 12px;
          height: 1px;
          background: $white;
          transform: translate(-50%, -50%);
        }

        &:after {
          height: 12px;
          width: 1px;
        }
      }
    }

    .slick-list {
      overflow: visible;
      margin-left: -10px;
    }

    .slick-slide {
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      margin-left: 10px;

      &.slick-active {
        opacity: 1;

        .open-preview {
          opacity: 1;
        }
      }

      &.slick-slide-prev {
        opacity: 0.2;

        & + .slick-active {
          opacity: 1;
        }
      }
    }
  }

  .lookbook-content {
    position: relative;
    z-index: 11;
    max-width: 400px;

    @include media-breakpoint-up(lg) {
      margin-left: 60px;
    }

    .title {
      color: $brand;
      margin-bottom: 20px;
    }

    a {
      margin-top: 25px;
      display: inline-block;
    }
  }
}

.lookbook-modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1200;

  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .modal-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }

  .lookbook-images {
    max-width: 80%;
  }

  .close-preview {
    position: absolute;
    top: 30px;
    right: 30px;
    color: $white;

    transform: scale(1);
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(0.9);
    }

    .cross {
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;

        width: 50px;
        height: 1px;
        background: rgba(255, 255, 255, 0.6);
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        height: 50px;
        width: 1px;
      }
    }
  }

  .slider-prev,
  .slider-next {
    top: 50%;
    transform: translateY(-50%);
  }

  .slider-prev {
    left: -30px;
    transform: translateY(-50%) rotate(180deg);
  }

  .slider-next {
    right: -30px;
  }
}
