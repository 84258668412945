/* ==========================================================================
   
   INSTAGRAM

========================================================================== */

.page.the-hire-collection {
	.instagram-feed {
		margin-top: 50px;

		@include media-breakpoint-up(lg) {
			margin-top: 100px;
		}		
	}
}

.instagram-feed-wrap {

	width: 100%;
	max-width: 750px;
	position: relative;

	@include media-breakpoint-up(lg) {

		max-width: 750px;

	}

	&.aos-animate {
		
		#sbi_images .sbi_item {

			&:nth-child(1) { opacity: 1; transition-delay: 0; }
			&:nth-child(2) { opacity: 1; transition-delay: 0.3s; }
			&:nth-child(3) { opacity: 1; transition-delay: 0.6s; }
			&:nth-child(4) { opacity: 1; transition-delay: 0.9s; }

		}

	}
	
	.icon-link {

		position: absolute;
		top: calc(50% - 32px);
		left: 0;
		right: 0;
		margin: 0 auto;
		background: linear-gradient(to top, rgb(195, 195, 195) 0%, #fff 100%);
		width: 52px;
		height: 52px;
		border-radius: 6px;
		z-index: 1;

		@media (max-width: 699px) {

			display: none;

		}

		.icon {

			position: relative;
			top: 10px;
			left: 10px;
			font-size: 32px;

		}

	}


}

body.home {

	#sbi_images .sbi_item {

		opacity: 0;
		transition: opacity 0.8s ease-in-out;

	}

}

.instagram-feed {

	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 50px;

	@include media-breakpoint-up(lg) {

		flex-direction: row;
		margin-bottom: 100px;

	}

	.type1A {

		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: -1;
		pointer-events: none;

	}

	.user {

		margin-bottom: 30px;

		@include media-breakpoint-up(lg) {
			
			margin-bottom: 0;
			margin-right: 120px;

		}

		p {

			font-family: $font-sacramento;
			font-size: 52px;
			color: $brand-dark-alt;

			span {

				font-family: $font-helvetica;
				font-size: 14px;
				display: block;
				margin-left: 115px;

			}

		}

	}

	.feed, .sbi_item {

		height: 172px;

		@media (min-width: 700px) {

			height: auto;
			max-width: 620px;
			width: 100%;

		}

	}

	.feed-wrap, #sbi_images {

		position: relative;
		display: flex;
		flex-wrap: nowrap;

		@media (max-width: 699px) {

			width: 100vw!important;
			padding-bottom: 30px!important;
			overflow-x: scroll;

		}

		@media (min-width: 700px) {

			flex-wrap: wrap;
			max-width: 800px;
			margin-left: auto;
			margin-right: auto;

		}

		.img, .sbi_photo {

			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;

			align-items: stretch;
			padding-top: 100%;

			height: 172px!important;

			margin-right: 10px;

			@media (max-width: 699px) {

				min-width: 172px!important;

			}

			@media (min-width: 700px) {

				//width: calc(50% - 10px)!important;
				height: 300px!important;
				margin-left: 5px;
				margin-right: 5px;
				margin-bottom: 10px;
				
				&:nth-last-child(-n+2) {

					margin-bottom: 0;

				}
				
			}

			&:last-child {

				margin-right: 0;

			}


		}

	}

}