.video-banner {
	background-color:#192536;
	height:400px;
	position: relative;
	overflow:hidden;

	@include media-breakpoint-up(xl) {
		height:500px;
	}

	.background-image {
		position: absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		@include background-cover();
		opacity:0.1;
		transition: 1s;
		pointer-events:none;
	}

	.inner {
		width: 100%;
		text-align: center;
		padding-left: 20px;
		padding-right: 20px;

		&__img {
			width: 40px;
		}

		&__title {
			font-size:28px;
			color:white;
			padding-top: 20px;
    		margin: 0;			
		}
	}

	a {
		display: flex;
		align-items:center;
		height: 100%;
    	width: 100%;	
	}	

	&:hover {
		.background-image {
			transform:scale(1.1);
		}
	}	
}
