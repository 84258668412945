.link1 {
	position: relative;
	text-transform:uppercase;
	font-size: 14px;
	font-family:$font-helvetica;

	&:before { 
	    content: "\e903";
	    position: absolute;
	    font-family: icomoon!important;
	    font-size: 45px;
	    color:$brand-alt;
    	width: 45px;
    	height: 60px;
    	margin-left: -22.5px;	
    	margin-top: -20px;
    	left:50%;  
    	z-index: -1;  

    	@include media-breakpoint-up(sm) {
	    	transform:scale(0);
	    	opacity:0;
	    	transition:0.3s;
    		
    	}
	}

	&:hover {
		color:$brand-dark;
		
		&:before { 
			opacity:1;
			transform:scale(1);
		}
	}
}

.link2 {
	font-size: 15px;
	color:$brand-darker;
	text-decoration: underline;
}