/* ==========================================================================
   
   POSTS

========================================================================== */

// Post Thumbnails
body.blog:not(.paged) .article-posts article.post {
	&:first-child {
		display: none;
	}
}

.article-posts {
	
	margin-bottom: 60px;

	@include media-breakpoint-up(md) {

		margin-left: -30px;
		margin-right: -30px;

	}

	@include media-breakpoint-up(lg) {

		margin-left: -50px;
		margin-right: -50px;

	}

	article.post {
		
		position: relative;
		width: 100%;
		margin-left: 15px;
		margin-right: 15px;
		margin-bottom: 60px;

		&:last-child {

			margin-bottom: 0;

		}

		&:nth-last-child(-n+2) {

			@include media-breakpoint-up(md) {

				margin-bottom: 0;

			}

		}

		&:hover {

			.entry-top {

				@include media-breakpoint-up(lg) {
				
					margin-bottom: 35px;

				}

			}

			.entry-meta {

				transform: rotate(-90deg) translateX(50%) translateY(-5px);

			}

			.entry-image .image {

				transform: scale(1.05);

			}

		}

		@include media-breakpoint-up(md) {

			width: calc(50% - 60px);
			margin-left: 30px;
			margin-right: 30px;

		}

		@include media-breakpoint-up(lg) {
			
			width: calc(50% - 100px);
			margin-left: 50px;
			margin-right: 50px;
			margin-bottom: 120px;

		}

		.entry-title {

			font-size: 28px;
			margin-bottom: 10px;

			@include media-breakpoint-up(lg) {

				margin-bottom: 20px;

			}

		}

		.entry-summary {

			.entry-content {
				
				margin-bottom: 30px;

			}

		}

		.entry-top {

			position: relative;
			margin-bottom: 20px;

			@include media-breakpoint-up(sm) {

				width: calc( 100% - 35px);

			}

			@include media-breakpoint-up(lg) {

				transition: margin-bottom 0.3s ease-in-out;
				margin-bottom: 45px;

			}
			
		}

		.entry-image {

			position: relative;
			overflow: hidden;
			
			width: 100%;
			height: 200px;

			@media (min-width: 500px) {

				height: 250px;

			}

			@include media-breakpoint-up(md) {

				height: 210px;

			}

			@include media-breakpoint-up(lg) {

				height: 300px;

			}

			.image {

				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				width: 100%;
				height: 100%;

				transform: scale(1);
				transition: transform 0.3s ease-in-out;

			}

		}

		.entry-meta {

			position: absolute;
			top: 50%;
			right: -15px;
			transform: rotate(-90deg) translateX(50%);
			transform-origin: right top;
			transition: transform 0.3s ease-in-out;

			font-size: 12px;
			color: $brand-light;

			@include media-breakpoint-down(xs) {

				display: none;

			}

		}

	}

}

// Inner Post Page
article.post-page {

	margin-bottom: 40px;

	@include media-breakpoint-up(lg) {

		max-width: 70%;
		margin-left: auto;
		margin-right: auto;

		margin-bottom: 100px;

	}

	@include media-breakpoint-up(xl) {

		max-width: 50%;

	}

}

.post-toolbar {

	color: $brand-dark;
	margin-bottom: 40px;
	align-items: center;

	a {

		color: $brand-dark;

	}

	.col:last-child {
		
		margin-left: auto;
		text-align: right;

	}

	.post-return {

		&:hover .arrow {

			margin-right: 55px;

			&:after {

				width: 35px;
			}

		}

		.arrow {

			display: inline-block;
			position: relative;
			top: -5px;
			margin-right: 70px;
			transition: margin-right 0.3s ease-in-out;

			@include arrow($brand-light);

			&:after {

				width: 50px;
				transition: width 0.3s ease-in-out;

			}
			
		}

	}

	.share {

		margin-bottom: 0;

		li {

			display: inline-block;
			margin-right: 15px;
			font-size: 14px;

			&:hover .icon {

				opacity: 1;

			}

			&:not(:first-child) {

				position: relative;
				top: 3px;

			}

			&:last-child {

				margin-right: 0;

			}

		}

		.icon {

			color: #192536;
			opacity: 0.3;
			font-size: 20px;
			transition: opacity 0.3s ease;

		}

	}

}

figure.wp-caption {

	display: flex;
    flex-direction: column;
    align-items: center;

    max-width: none;
    margin-top: 30px;
    margin-bottom: 30px;

    @include media-breakpoint-up(xl) {

    	flex-direction: row;
    	margin-left: -280px;
    	margin-right: -280px;
    	margin-top: 90px;
    	margin-bottom: 90px;

    }

   .wp-caption-text {

		font-size: 38px;
		font-family: $font-secondary;
		text-align: center;
		color: $brand;
		margin-top: 30px;

		padding-left: 15px;
		padding-right: 15px;

		@media (min-width: 420px) {

			font-size: 42px;

		}

		@include media-breakpoint-up(sm) {

			font-size: 52px;

		}

		@include media-breakpoint-up(xl) {

			margin-left: 100px;
			margin-top: 0;
			padding-left: 0;
			padding-right: 0;

		}

   }

   img {

   		@include media-breakpoint-up(xl) {

   			max-width: 620px;

	   	}

   }

}

.entry-content {

	blockquote {

		margin-top: 40px;
		margin-bottom: 40px;

		p {
			
			font-family: $font-secondary;
			font-size: 52px;
			color: $brand;
			line-height: 70px;
			text-align: center;

		}

	}
}