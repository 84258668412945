.item-details {
  .sticky-elem {
    position: static;
    right: 0;
    opacity: 0;
    transition: 1s;
    transition-delay: 0.2s;

    @include media-breakpoint-up(lg) {
      position: fixed;
    }


    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: #e2e2e2;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #cacaca;
    }

    &.loaded {
      opacity: 1;
    }

    &.bottom-reached {
      @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 100px;
      }
    }

    &.viewport-short {
      @include media-breakpoint-up(lg) {
        overflow-y: scroll;
      }
    }
  }

  .custom-scroll {
    @include media-breakpoint-up(sm) {
      height: 90px;
      overflow-y: scroll;
    }


    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #e2e2e2;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $brand-dark;
    }
  }

  .product-slider {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }

    .item {
      width: 100%;
      height: 600px;

      @include background-cover;

      @include media-breakpoint-up(sm) {
        height: 900px;
      }
    }
  }

  .item-details-images {
    margin-right: -15px;
    margin-left: -15px;
    position: relative;
    padding-bottom: 20px;
    opacity: 0;
    transition: 1s;

    &.contact-image {
      opacity: 1;
      margin-bottom: 40px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 90px;
      }


      .group-images .single-image {
        height: 680px;
      }
    }

    &.slider {
      display: block;
      height: 600px;

      @include media-breakpoint-up(sm) {
        height: 900px;
      }


      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &.blocks {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-right: 0;
    }


    &.loaded {
      opacity: 1;
    }

    .group-images {
      display: none;

      &.active {
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
        }
      }

      .single-image {
        margin-bottom: 5px;
        height: 500px;

        @include background-cover;

        &.featured {
          height: 700px;

          @media (min-width: 1600px) {
            height: 1100px;
          }
        }
      }
    }
  }

  .item-details-fieldset {
    padding-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;

    @include media-breakpoint-up(lg) {
      padding-top: 60px;
    }


    .heading-group {
      text-align: center;

      &__hdr {
        position: relative;
        color: $brand-dark;
        display: inline-block;
        margin-bottom: 30px;

        &:after {
          content: '';
          position: absolute;
          bottom: -15px;
          left: 0;
          width: 100%;
          height: 1px;
          border-bottom: 1px dashed $brand-dark;
        }
      }

      &__para {
        color: $brand-light;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 20px;
      }
    }

    .inner-form {
      padding-top: 10px;

      .hours {
        font-size: 14px;
        color: $brand-dark;

        td {
          padding-bottom: 10px;
          padding-right: 20px;
        }
      }

      .inner-form-row {
        border-bottom: 1px solid #cccccc;
        padding-top: 15px;
        padding-bottom: 15px;

        &.large {
          padding-top: 30px;
          padding-bottom: 30px;
        }

        @include media-breakpoint-up(sm) {
          padding-left: 15px;
          padding-right: 15px;
        }


        &:first-of-type {
          border-top: 1px solid #cccccc;
        }

        label {
          color: $brand-light;
          margin: 0;
          font-size: 14px;
          padding-top: 5px;
        }

        p,
        li {
          font-size: 14px;
          color: $brand-dark;
        }

        p {
          @include media-breakpoint-up(sm) {
            padding-right: 15px;
          }
        }

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;

          &.store li {
            width: 100%;
          }

          li {
            display: inline-block;
            width: 45%;
          }
        }

        .select-container {
          position: relative;
          overflow: hidden;
          margin-left: -4px;

          &:after {
            content: '';
            position: absolute;
            right: 15px;
            width: 30px;
            height: 20px;
            top: 50%;
            margin-top: -10px;

            @include background-contain;

            background-image: url("../images/down-arrow.svg");
            pointer-events: none;
          }

          select {
            cursor: pointer;
            width: 105%;
            border: 0;
            background-color: transparent;
            min-height: 40px;
            font-size: 14px;
            transition: 0.5s;

            &:focus {
              outline: 0;
            }
          }

          &:hover {
            select {
              opacity: 0.7;
            }
          }
        }
      }

      .inner-form-base {
        padding-top: 40px;
        text-align: center;
      }
    }
  }
}

.contact-form {
  display: flex;
  justify-content: center;
  max-width: 350px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .wpcf7-response-output {
    border: none;
    padding: 0;
    margin: 0 0 40px 0;
    color: $brand-red;
    font-size: 16px;
  }
}

.form-fields {
  display: flex;
  flex-direction: column;

  .form-field {
    margin-bottom: 50px;

    label {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      margin-bottom: 0;

      & > span {
        color: $brand-light;

        &:not(.wpcf7-form-control-wrap) {
          padding-left: 10px;
        }
      }

      .wpcf7-form-control-wrap {
        margin-top: 15px;
      }
    }

    input,
    textarea {
      padding: 15px 20px;
      color: $brand-dark-alt;
      border-color: rgba(0, 0, 0, 0.3);
      border-width: 1px;
      transition: border 0.3s ease-in-out;
      width: 100%;

      &:focus {
        outline: none;
        border-color: rgba(0, 0, 0, 0.6);
      }

      &.wpcf7-not-valid {
        border-color: lighten($brand-red, 30%);
      }
    }

    &.form-submit {
      text-align: center;

      button {
        min-width: 160px;
        font-size: 14px;
      }
    }

    &.form-radio {
      .radio-text {
        text-align: center;
        margin-bottom: 15px;
      }

      input[type="radio"] {
        position: absolute;
        visibility: hidden;
        opacity: 0;

        &:checked + span {
          background: $brand-red;

          &:after {
            height: 100%;
          }
        }

        & + span {
          @extend .btn;

          font-size: 14px;
          text-transform: none;
          background: rgba($brand-red, 0.3);
          display: block;
        }
      }

      .wpcf7-radio {
        display: flex;
        flex-direction: row;
        margin-left: -10px;
        margin-right: -10px;

        .wpcf7-list-item {
          width: calc(50% - 20px);
          margin-left: 10px;
          margin-right: 10px;
          display: block;
        }
      }
    }

    .wpcf7-list-item {
      margin: 0;
    }

    .wpcf7-not-valid-tip {
      display: none;
    }
  }
}
