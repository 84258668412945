.three-column {
	margin-top: 30px;
    padding-bottom: 50px;

    .block-title {

    	margin-top: -30px;
    	
    }

	.three-column-single {
		width:100%;
		background-color:#151414;
		position: relative;
		display: flex;
    	align-items: center;	
    	overflow:hidden;
    	height: 400px;
    	margin-bottom: 40px;

    	@include media-breakpoint-up(lg) {
			height:600px;
			margin-bottom: 0;
    	}

		.background-image {
			position: absolute;
			top:-50px;
			right:-50px;
			bottom:-50px;
			left:-50px;
			opacity:1;
			@include background-cover();
			transition:1s;
		}

		.group-content {
			text-align: center;
			width:100%;
			color:white;
			z-index: 1;

			&__subhdr {
				position: absolute;
				color:white;
				top:0;
				width: 100%;
				padding-top: 35px;
				font-family: $font-georgia;
				opacity:0;
				transition: 1s;
				transform: translateY(50px);
			}

			&__hdr {
				position: relative;
				font-family:$font-secondary;
				font-size: 62px;
				color:white;
    			max-width: 200px;
    			margin-left: auto;
    			margin-right: auto;				

				&:after { 
					content:'';
    				position: absolute;					
					width:100%;
					border-bottom:1px dashed white;
					bottom:10px;
    				left: 0;
    				width: 100%;
				}
			}

			&__para {
				position: absolute;
				color:white;
				bottom:0;
				opacity:0;
				transition: 1s;
				transform: translateY(-50px);
				padding:0 25px 25px;

				@include media-breakpoint-up(xl) {
					padding: 0px 50px 50px 50px;
				}
			}
		}

		&:hover {
			.background-image {
				opacity:0.3;
				filter:blur(10px);
			}

			.group-content {

				&__subhdr {
					opacity:1;
					transform: translateY(0px);
				}

				&__para {
					opacity:1;
					transform: translateY(0px);
				}
			}
		}
	}
}
