/* ==========================================================================
   
   TYPE
   
========================================================================== */

// Headings
#{headings(1,6)} {

	color: $brand;
	font-weight: $font-regular;
	font-family: $font-base;

	margin-top: 0;

}

h1 {

	font-size: 28px;

}

h2 {

	font-size: 72px;
}

h3 {

	font-size: 28px;

}

// Paragraph
p {

	color: $text-color;
	font-size: 14px;
	font-family: $font-base;
	line-height: 28px;

	&:last-of-type {

		margin-bottom: 0;

	}

}

.helvetica {
	font-family:$font-helvetica;
}

// Links
a {

	color: $brand;
	transition: color 0.2s ease;

	&.underline {

		border-bottom: 1px solid $brand;
		
	}

	&:hover {

		color: $brand-light;
		text-decoration: none;
	}
	&:focus {
		outline: none;
	}
}

.type1A {
	font-family:$font-secondary;
	font-size:160px;
	white-space: nowrap;
	opacity: 0.05!important;
	line-height: 1;
	@include media-breakpoint-up(lg) {
		font-size: 380px;
	}
}

.type2A {
	font-family:$font-base;
	font-size:32px;
}

.type2B {
	font-family:$font-base;
	font-size:15px;
}

// SVG Animation
.bg-animation {
	position: absolute;
	top: -130px;
	left: 8%;
	right: 0;
	z-index: -1;
	pointer-events: none;
	@include media-breakpoint-down(lg) {
		display: none;
	}
}

svg {
	display: block;
	width: 100%;
	height: 100%;
	defs {
		display:none;
	}

	.bg-animation-right & {
		transform:translateX(30%);
	}

	path, line, circle {
		stroke: #fff;
		//stroke:red;
		stroke-width: 12px;
		fill: transparent;

		&#t-line {
			transform:scaleX(0);
			animation:testimonials-line .5s linear 6s forwards;
		}
		&#t-dot {
			opacity:0;
			animation:testimonials-dot .1s linear 7s forwards;
		}
	}
}

#collection {
	stroke-dasharray: 3150px;
	stroke-dashoffset: -3150px;
	animation:collection-anim 3s linear 3s forwards;
}

#testimonials {
	//stroke-dasharray: 0;
	//stroke-dashoffset: 0;
	stroke-dasharray: 5765px;
	stroke-dashoffset: -5765px;
	animation:testimonials-anim 3s linear 3s forwards;
}

#bespoke {
	stroke-dasharray: 4670px;
	stroke-dashoffset: -4670px;
	animation:bespoke-anim 3s linear 3s forwards;
}

.collection-text, .testimonials-text, .bespoke-text {
	color:$grey;
	fill: $grey;
	top:0;
	left:0;
	position:absolute;
}

.collection-text {

}

.testimonials-text {
	width:95%;
	transform:translateY(15px);
}

.bespoke-text {
	width:102%;
	transform:translate(-266px, 0);
}



// SVG Animations
@keyframes collection-anim {
  from {stroke-dashoffset: 3150px}
  to {stroke-dashoffset: 0px;}
}

@keyframes testimonials-anim {
	from {stroke-dashoffset: 5765px}
	to {stroke-dashoffset: 0px;}
}

@keyframes bespoke-anim {
	from {stroke-dashoffset: 4670px}
	to {stroke-dashoffset: 0px;}
}

@keyframes testimonials-line {
	from {transform:scaleX(0);}
	to {transform:scaleX(1);}
}
@keyframes testimonials-dot {
	from {opacity:0;}
	to {opacity:1;}
}
