// e.g '@include z-index(header)' on element;

$z-index: (
  header		: 100,
  hero			: 50,
  heroInner		: 75
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}