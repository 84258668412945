/* ==========================================================================
   
   HERO

========================================================================== */
body.the-hire-collection .hero .hero-bg .bg {
    @include media-breakpoint-down(xs){
        background-position: left -450px top 0;   
   
    }
}
.hero {

	position: relative;
	height:100vh;
	width: 100%;
	@include z-index(hero);

	@include media-breakpoint-up(md) {
		//height: calc(100vh + 10vh);
	}

	opacity: 0;
	transition: opacity 0.4s ease;

	& + .row:not(.page-reveal) {

		padding-top: 0;
		
	}

	&.dark-text {
		.inner-content {
			h1 {
				color:$brand;
			}
			p {
				color:$text-color;
			}
			.sub-title {
				border-bottom: 1px dashed $brand;
			}
		}
	}

	&.error-hero {
		top:0;
		height:90vh;
		.hero-inner {
			padding-top:0;
		}
		.hero-bg {
			span {
				font-family:$font-secondary;
				font-size:300px;
				color:rgba($grey, .5);
				text-align:center;
				display:block;
				position:absolute;
				top:50%;
				left:50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	&.hero-page {

		opacity: 1;
		
	}

	&.hero-small {

		height: 85vh;

	}

	&.hero-post {

		height: 75vh;
		margin-bottom: 60px;

		a {

			color: $white;

		}

		.inner-content {

			.title {

				font-size: 42px;
				line-height:1.5em;
				margin-bottom: 30px;

				@include media-breakpoint-up(lg) {

					font-size: 72px;

				}

			}

			a {

				font-size: 16px;

			}

		}

		.bg:after {

			content: '';
			width: 100%;
			height: 100%;
			background: rgba(8, 54, 90, 0.5);
			position: absolute;
			top: 0;
			left: 0;

		}

		.time {

			color: $white;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 10px;

		}

		&-single {

			margin-bottom: 25px;

			.time {

				position: static;

			}

		}

		.read-article {

			h2 {

				font-size: 16px;

			}

		}

	}

	&.is-reveal {

		opacity: 1;

		.hero-bg {

			position: fixed;
			z-index: -1;

		}

		.hero-inner {

			position: fixed;

			.inner-reveal {

				//opacity: 1;

			}

		}

	}

	.hero-bg {

		width: 100%;
		height: 100%;
		background: $white;
		overflow: hidden;
		.bg {

			background-size: cover;
			background-repeat: no-repeat;
			background-position:left -100px top 0;
			width: 100%;
			height: 100%;
			transform: scale(1.1);
			animation: hero 8s forwards;

			@include media-breakpoint-up(md) {
				background-position: center top;
			}
			&:before {
				content:'';
				position: absolute;
				width:100%;
				height: 100%;
				z-index: 9;
				background-color: rgba($brand, 0.2);
			}

		}

	}


	.hero-inner {

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;

		@include media-breakpoint-up(md) {
			padding-top: 100px;
		}
		@include z-index(heroInner);

	}

	.inner-content {

		position: relative;
		width: 100%;
		height: 100%;
		color: $white;

		justify-content: center;
		display: flex;
		flex-direction: column;
		text-align: center;

		&.center {
			align-items: center;
		}

		.group-content {
    		max-width: 400px;
    		margin-left: auto;
    		margin-right: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.title-image {
			max-height: 80px;
			max-width: 80px;
			margin-bottom: 20px;
		}

		.title {

			font-family: $font-secondary;
			font-size: 62px;
			color: $white;
			text-align: center;
			margin-top: 10px;
			line-height: 1.2;

			@include media-breakpoint-up(lg) {

				font-size: 105px;

				margin-top: 0;

			}

		}

		.sub-title {

			font-size: 26px;
			color: $white;
			border-bottom: 1px dashed $white;
			padding-bottom: 15px;
			margin-bottom: 10px;
    		transition: all 0.6s ease;

			&.revealed {

				color: $brand;
				border-color: $brand;

			}

		}

		.paragraph {
			padding-top: 15px;
			max-width: 400px;
			margin-left: auto;
			margin-right: auto;
			color:white;
		}

		p {

			font-size: 16px;
			margin-bottom: 20px;

		}

	}

	.inner-reveal {

		position: absolute;
		top: 50%;
		left: 5vw;
		right: 5vw;
		transform: translateY(-50%);
		text-align: center;
		padding-top: 220px;

		@include media-breakpoint-up(lg) {

			padding-top: 140px;
			
		}

		opacity: 0;

		&.active {

			opacity: 1;
			transition: opacity 0.4s linear;
		}

		@include media-breakpoint-up(sm) {

			max-width: 50%;
			margin-left: auto;
			margin-right: auto;

		}

		@include media-breakpoint-between(sm, lg) {
			max-width: 70%;
			padding-top: 170px;

		}

		p {



		}

	}

}

// Arrow
body.home .hero .arrow {

	@include media-breakpoint-down(sm) {
		top: calc(100% - 65px);
	}

}
.hero .arrow {

	position: absolute;
	left: 0;
	right: 0;
	top: calc(100% - 90px);
	margin-left: auto;
	margin-right: auto;
	
	background: $white;
	height: 0;
	width: 1px;
	opacity: 0;

	animation: heroArrow 1s forwards;
	animation-delay: 0.8s;
	transition: background 0.6s linear;

	&.revealed {

		background: $brand-light;

		&:after, &:before {

			border-color: $brand-light;

		}

	}

	&:before {

	    content: '';
		position: absolute;
		bottom: 0px;
		left: 0;
		right: 0;
		width: 2px;
		height: 50px;
		border-right: 1px solid #fff;
    	transform: translateX(-50%);
		transition: border-color 0.6s linear;

	}

	&:after {

		content: "";
		position: absolute;
		bottom: 40px;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		display: inline-block;
		width: 8px;
		height: 8px;
		border-right: 1px solid #fff;
		border-top: 1px solid #fff;
		transform: rotate(135deg) translate(5px, 0px);
		bottom: 3px;
		transition: border-color 0.6s linear;

	}

}

.reveal-fix {

	width: 100%;
	min-height: 100vh;

}