/* ==========================================================================
   
   FOOTER

========================================================================== */

footer.primary {

	background: $brand-dark;
	padding: 55px 0;
	color: $white;

	@include media-breakpoint-up(lg) {

		padding: 85px 0;

	}

	a {

		color: $white;

	}

	ul {

		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
		text-align: center;
		font-family: $font-helvetica;

		@include media-breakpoint-up(lg) {

			text-align: left;

		}

		li {

			margin-bottom: 20px;

			&:last-child {

				margin-bottom: 0;

			}

		}

	}

	.logo img {

		max-width: 170px;

	}

}

.footer-container {

	display: flex;
	align-items: center;
	flex-direction: column;

	@include media-breakpoint-up(lg) {

		flex-direction: row;

	}

	.logo {

		width: 100%;
		position: relative;
		text-align: center;

		@include media-breakpoint-up(lg) {

			width: 30%;
			text-align: left;

		}

		&:after {

			content: "";

			@include media-breakpoint-up(sm) {


				border-bottom: 1px dashed #fff;
				display: block;
				max-width: 250px;
				margin-top: 20px;
	    		margin-bottom: 40px;
				margin-left: auto;
				margin-right: auto;
				
			}

		    @include media-breakpoint-up(lg) {

			    position: absolute;
			    top: -50px;
			    right: 80px;
			    width: 1px;
			    height: calc(100% + 100px);
			    border-right: 1px dashed $white;
			    border-bottom: none;

			    margin-top: 0;
			    margin-bottom: 0;

		    }

		}

	}

	.footer-menus {

		width: 100%;
		@extend .d-flex;
		@extend .flex-wrap;

		@include media-breakpoint-up(sm) {
			width: 70%;
		}

		.widget {
			flex: 1 0 100%;
			@extend .mt-3;
			@extend .mt-sm-0;
			@include media-breakpoint-up(sm) {
				flex: 1 0 50%;
				margin-bottom: 40px;
				&:nth-last-child(-n+2) {
					margin-bottom: 0;
				}
			}

			@include media-breakpoint-up(lg) {
				flex: 1 0 25%;
				margin-bottom: 0;
			}

		}

	}

}

footer.secondary {

	background: #131D2B;
	text-align: center;
	padding: 18px 0;

	p {

		margin-bottom: 0;
		color: $white;
		font-size: 12px;
		line-height: 100%;
		font-family: $font-helvetica;

	}

}

.book-appointment {

	position: relative;
	width: 100%;
	height: 328px;

	@include media-breakpoint-up(lg) {

		height: 428px;

	}

	.appointment {

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;

		background: rgba($brand, 0.7);
		color: $white;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 0 20px;

		.title {

			font-size: 28px;
			margin-bottom: 20px;

		}

		p {

			color: $white;
			max-width: 380px;
			margin-bottom: 30px;

		}

	}

	.map {

		background: url('../images/map.png') no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}

}