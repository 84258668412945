/* ==========================================================================
   
   BUTTONS

========================================================================== */

.btn,
button {

	position: relative;
	display: inline-block;
	border-radius: 0;

	color: $white;
	background: $brand;
	padding: 22px 30px;
	text-transform: uppercase;
	font-family: $font-helvetica;

	&:hover {

		color: $white;

	}

	&:focus {

		box-shadow: none;
		outline: none;
		
	}

}