.profile-listing {
	padding-top: 50px;
	padding-bottom: 25px;
	position:relative;

	@include media-breakpoint-up(sm) {

		padding-bottom: 50px;

	}

	@include media-breakpoint-up(lg) {
		padding-top: 100px;
		padding-bottom: 0;
	}

	.single-profile {
		position: relative;
		padding-bottom: 50px;

		@include media-breakpoint-up(md) {
			padding-bottom: 25px;
		}

		@include media-breakpoint-up(lg) {
			padding-bottom: 50px;
			padding-bottom: 100px;
		}

		&.single-profile--alt {
			padding-bottom: 100px;

			.single-profile-textarea__bg-text {
				top: 10px;
			    left: -50px;

				@include media-breakpoint-up(lg) {
					top: -100px;
					left: -160px;

				}	
			}

		}

		&__bg-text {
			position:absolute;
			pointer-events: none;
			left: 0;
            bottom: -20px;
			opacity:.2!important;

			@include media-breakpoint-up(md) {
				bottom:auto;
				top: -150px;
				left: 0;
			}
		}

		.single-profile-picture {
    		z-index: 1;
    		position: relative;
    		height: 300px;
    		margin-bottom: 20px;

    		@include media-breakpoint-up(md) {
				//height:100%;
				height:680px;
				margin-bottom:0px;
    		}

			.inner-image {
				height:100%;
				@include background-cover();
				@include media-breakpoint-up(lg) {
					width:90%;
					&.alt {
						width: 200%;
						margin-left: -100%;
					}
				}
			}
		}

		
		.single-profile-icon {
			margin-bottom: 20px;
			&.suit {
				max-width: 40px;
			}
			&.spool {
				max-width: 80px;
			}
		}
		.single-profile-textarea {
			@include media-breakpoint-up(md) {
				padding-left: 40px;
			}

			&__bg-text {
				position:absolute;
    			top:10px;
			    left: -50px;
    			pointer-events: none;

				@include media-breakpoint-up(sm) {
					top: -130px;
				}

    			@include media-breakpoint-up(lg) {
				    top: -250px;
	    			left: -100px;

    			}
			}
			&__hdr {
				color:$brand-dark;
				margin-bottom: 0;
			}
			&__title {
				color:$brand-darker;
				margin-bottom: 0;

				&.quote-style {
					font-family:$font-secondary;
					margin-top:20px;
					font-size:32px;
					font-style: italic;
					&:before, &:after {
						content:'"';
					}
				}
			}
			&__copy {
				margin-top: 25px;
				max-height:9999px;
				overflow:hidden;
				@include transition(max-height 1s ease-in-out);
				@include media-breakpoint-up(lg) {
					max-width: 80%;
				}

				&.ellipses {
					//@include webkit-line-clamp(4, auto);
					max-height:110px;
					@include transition(max-height 1s ease-in-out);
				}
			}

			&__link {
				display:inline-block;
				margin-top: 35px;
			}

			.readmore {
				display: block;
				margin-top: 8px;
				cursor:pointer;
			}
		}

		&.left {
		}

		&.right {
			@include media-breakpoint-up(md) {
				height:750px;
			}
		}

		&:nth-of-type(4n+1) {
			@include media-breakpoint-up(md) {
				height:600px;
			}

			@include media-breakpoint-up(lg) {
				height:750px;
			}
		}		

		&:nth-of-type(4n+3) {
			@include media-breakpoint-up(md) {
				height:700px;
			}

			.single-profile-picture {
				@include media-breakpoint-up(md) {
					width: 125%;
	    			float: right;
				}
			}
		}

		.bootstyle1 {
			@include media-breakpoint-up(lg) {
				padding-right: 30px;
			}
		}

		.bootstyle2 {
			@include media-breakpoint-up(lg) {
				padding-left: 30px;
			}
		}
		.row {
			@include media-breakpoint-up(md) {
				align-items: center;
				justify-content: center;
			}
		}

		.row,
		div[class^="col-"] {
			@include media-breakpoint-up(md) {
				height:100%;

			}
		}
	}
}

.about-employee {

	margin-bottom: 30px;

	.employee-image {

		position: relative;
		z-index: 1;

	}

	.employee-content {

		margin-top: 40px;

		@include media-breakpoint-up(md) {

			margin-top: 92px;
			max-width: 355px;
			margin-left: 40px;

		}

		@include media-breakpoint-up(xl) {

			margin-left: 160px;

		}

		header {

			margin-bottom: 35px;

		}

		.title {

			color: $brand-dark;
			font-size: 28px;

		}

		.position {

			color: $brand-dark;
			font-size: 14px;
			margin-bottom: 0;

		}

		.type1A {

			position: absolute;
			left: -50px;
			top: -30px;
			pointer-events: none;

			@include media-breakpoint-up(md) {

				left: -100px;

			}

		}

	}
}